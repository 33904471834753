import axios from 'axios';
import store from '../store';
import { message } from '@/utils/resetMessage';
import router from '@/router';

// 防止circle import
let vue = async () => {
  return (await import('../main')).vue;
};
//axios.defaults.baseURL = 'http://106.14.180.64:3456';
// axios.defaults.baseURL = "http://localhost:8765";
// axios.defaults.baseURL = "http://tmxk.yibaiapp.com";
axios.defaults.baseURL = 'https://www.ht-fast.com/';
export function initRequestAuthorization(uid) {
  axios.defaults.headers.common['Authorization'] = uid;
}

axios.interceptors.request.use(
  (config) => {
    // 方法
    config.cancelToken = new axios.CancelToken(function (cancel) {
      store.commit('pushToken', { cancelToken: cancel });
    });

    if (
      config.url != 'subuser/logincheck' &&
      config.url != 'homeVideo/findTrue' &&
      config.url != 'homeText/findByShow' &&
      config.url != 'homeImg/findTrue' &&
      config.url != 'subuser/tokenverify' &&
      config.url != 'subuser/duanxin'
    ) {
      console.log('config.url :>> ', config.url);
      const tokenAxios = axios.create({
        baseURL: 'https://www.ht-fast.com/',
      });
      tokenAxios
        .post('subuser/sessionTokenVerify', {
          id: window.localStorage.getItem('userid'),
          token: window.sessionStorage.getItem('sessionToken'),
          type: 'user',
        })
        .then((res) => {
         store.commit('$_change_overTimeToken', true) 
          console.log('res :>> ', res);
          if (res.data.data.status == 0) {
            console.log('token', 0);
              
            store.commit('$_setSessinoToken', res.data.data.sessionToken);
            // console.log("sotre.state :>> ", store.state);
          } else if (res.data.data.status == 1) {
            // console.log('token', 1);
             //异地登录提醒
            message.warning(res.data.data.message);
            // window.localStorage.clear();
            window. localStorage.removeItem('userInfo')
            window.localStorage.removeItem('userid')
            window. localStorage.removeItem('user_name')
            window. localStorage.removeItem('user_email')
            window. localStorage.removeItem('token')
            // window.sessionStorage.removeItem("sessionToken");
            router.push({ path: '/home' });
            // router.go(0)
            console.log('测试111 :>> ', );
          } else if (res.data.data.status == 2) {
            //超时提醒
            message.warning(res.data.data.message);
            window.sessionStorage.removeItem('sessionToken');
            router.push({ path: '/home' });
           
            // console.log('this.$store.state.overTimeToken :>> ', store.state.overTimeToken);
            store.commit('$_change_overTimeToken', false)  
              // console.log('this.$store.state.overTimeToken :>> ', store.state.overTimeToken);
          }
        });
    }
    return config;
  },
  (err) => {
    console.log(err);
    return err;
  },
);

if (!axios.interceptors.response.handlers.length) {
  axios.interceptors.response.use((resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      let data = resp.data;
      if (!('code' in data)) {
        return resp;
      }
      if (data.code == 0) {
        return data.data;
      } else {
        console.log('=========');
        console.log(data);
        console.log('=========');
        vue().then((e) => e.$notify.error(data.error));
        throw data.data;
      }
    } else {
      //error
    }
  });
}

export class HttpClient {
  static get(url, options, params = {}) {
    return axios.get(
      url,
      {
        params,
      },
      options,
    );
  }

  static post(url, body, options) {
    return axios.post(url, body, options);
  }

  static put(url, body) {
    return axios.put(url, body);
  }

  static delete(url, params) {
    return axios({
      url,
      params,
      method: 'delete',
    });
  }
}
